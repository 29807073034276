// ==========================================================================
// Variables
// ==========================================================================
/*--------------------------------------------------------------
## Bootstrap variables
--------------------------------------------------------------*/
$grid-columns: 12 !default;
$grid-gutter-width: 24px !default;
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px,
);
$container-max-widths: (
    sm: 536px,
    md: 728px,
    lg: 944px,
    xl: 1144px,
    xxl: 1340px,
);
/*--------------------------------------------------------------
## Colors
--------------------------------------------------------------*/
$color__white: #ffffff;
$color__black: #000000;
$color__white-50: #8E8E8E;
$color__black-80: rgba(0, 0, 0, 0.8);
$color__black-70: rgba(0, 0, 0, 0.7);
$color__black-65: rgba(0, 0, 0, 0.65);
$color__black-60: rgba(0, 0, 0, 0.6);
$color__black-40: rgba(0, 0, 0, 0.4);
$color__black-35: rgba(0, 0, 0, 0.35);
$color__black-30: rgba(0, 0, 0, 0.3);
$color__black-25: rgba(0, 0, 0, 0.25);
$color__black-20: rgba(0, 0, 0, 0.2);
$color__black-10: rgba(0, 0, 0, 0.1);
$color__black-05: rgba(0, 0, 0, 0.05);
$color__grey-medium: #707070;
$color__grey-medium-light: #DDDBD7;
$color__grey-light: #F5F4F4;
$color__grey-table: #EEEEEE;
$color__green-primary: #72B02A;
$color__green-secondary: #558321;
$color__green-success: #4CB627;
$color__green-success-25: rgba(76, 182, 39, 0.25);
$color__red-error: #E35050;
$color__red-error-25: rgba(204, 69, 60, 0.25);
$color__orange: #F5A40B;
$color__yellow: #F5DA0B;
$color__blue: #2872D6;
$color__red-pink: #ea2126;
/*--------------------------------------------------------------
## typography
--------------------------------------------------------------*/
$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';
$font__fa-familly: Font Awesome\ 6 Pro;
$font-main: "Poppins", helvetica, arial, sans-serif;
$font__main: "Poppins", helvetica, arial, sans-serif;
$font-mono: monospace;
$font__heading: "Poppins", helvetica, arial, sans-serif;
$font__size-body: 16px;
$font__size-14: 0.875rem;
$font__size-13: 0.8125rem;
$font__size-12: 0.75rem;
$font__code: Monaco, Consolas, 'Andale Mono', 'DejaVu Sans Mono', monospace;
$font__pre: 'Courier 10 Pitch', Courier, monospace;
$font__line-height-body: 1.2857em;
// $font__line-height-pre: 1.6;
$font__line-height-reg: 1.3em;
$font__weight-bold: 700;
// $font__weight-semibold: 600;
$font__weight-medium: 500;
$font__weight-regular: 400;
$font__top-position-correction: 0.11em;
/*--------------------------------------------------------------
## transitions
--------------------------------------------------------------*/
$transition__color-link: color 0.2s linear;
$transition__opacity: opacity 0.2s linear;
$transition__color-back: background-color 0.2s linear;
$transition__color-border: border 0.2s linear;
$transition__transform: transform 0.2s linear;
$transition__box-shadow: box-shadow 0.2s linear;
// $transition__transform-nav: transform 0.4s linear;
// $transition__transform-nav-fast: transform 0.1s ease-in-out;
// Easings
$ease-in-out-cubic: cubic-bezier(0.65, 0, 0.35, 1); // https://cubic-bezier.com/#.65,0,.35,1
$ease-in-out-airbag: cubic-bezier(0.3, 0, 0, 1); // https://cubic-bezier.com/#.3,0,0,1
/*--------------------------------------------------------------
## Forms
--------------------------------------------------------------*/
/* Input range */
$form-range-thumb-width: 0.8rem;
$form-range-thumb-height: $form-range-thumb-width;
$form-range-thumb-bg: $color__green-primary;
$form-range-thumb-border-radius: 0px;
$form-range-track-height: .3rem;
$form-range-track-bg: $color__green-primary;
$form-range-track-border-radius: 0rem;
/*--------------------------------------------------------------
## Offcanvas
--------------------------------------------------------------*/
$offcanvas-horizontal-width: 475px;
/*--------------------------------------------------------------
## Structure
--------------------------------------------------------------*/
$promobar__height: 45px;
$navbar__height: 100px;
$navbar__height-scroll: 70px;
$navbar__height-mobile: 55px;
$navbar__border-bottom: 2px;
$navbar__btn-rent-width: 130px;
$btn-chat-appointement-width: 80px;
$btn-chat-appointement-width-scroll: 60px;
$btn-chat-appointement-height: 275px;
$btn-chat-appointement-height-scroll: 235px;
$btn-swiper-nav-width: 40px;
$btn-swiper-nav-height: 70px;
/*--------------------------------------------------------------
## Border radius
--------------------------------------------------------------*/
// $radius-button: 8px;
// $radius-tabs: 8px;
// $radius-form: 4px;
// $radius-cards: 6px;
// $radius-cards-blog: 10px;
/*--------------------------------------------------------------
## Box shadow
--------------------------------------------------------------*/
$boxshadow__submenu: 0px 24px 65px rgba(20, 20, 43, 0.16);
// $boxshadow__card-medium: 0px 0px 8px 1px $color__black-10;
// $boxshadow__card-medium-hover: 0px 0px 20px 1px $color__black-20;
// $boxshadow__card-light: 0px 0px 5px 1px $color__black-05;
// $boxshadow__card-light-hover: 0px 0px 10px 1px $color__black-10;
/*--------------------------------------------------------------
## z-index
--------------------------------------------------------------*/
// $zindex__navbar-main: 1100;
/*--------------------------------------------------------------
## Bootstrap form variables
--------------------------------------------------------------*/
// $input-btn-padding-y:         .375rem;
// $input-btn-padding-x:         .75rem;
// $input-btn-font-family:       null;
// $input-btn-font-size:         $font__size-body;
// $input-btn-line-height:       $line-height-base;
// $input-btn-focus-width:         1px;
// $input-btn-focus-color-opacity: .25;
// $input-btn-focus-color:         $color__blue-light;
// $input-btn-focus-blur:          0;
// $input-btn-focus-box-shadow:    0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color;
// $input-btn-padding-y-sm:      .25rem;
// $input-btn-padding-x-sm:      .5rem;
// $input-btn-font-size-sm:      $font-size-sm;
// $input-btn-padding-y-lg:      .5rem;
// $input-btn-padding-x-lg:      1rem;
// $input-btn-font-size-lg:      $font-size-lg;
// $input-btn-border-width:      $border-width;
// $form-feedback-invalid-color:       $color__red;