/*--------------------------------------------------------------
## FORMS General
## Formie
--------------------------------------------------------------*/
/*-------------------------------------
    PLACEHOLDER
------------------------------------*/
::-webkit-input-placeholder {
    /* Chrome */
    color: $color__grey-medium;
    opacity: 1;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: $color__grey-medium;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: $color__grey-medium;
}

:-moz-placeholder {
    /* Firefox 4 - 18 */
    color: $color__grey-medium;
}

/*--------------------------------------------------------------
## Fields
--------------------------------------------------------------*/
input[type="text"],
input[type="text"].fui-input,
input[type="email"],
input[type="email"].fui-input,
input[type="url"],
input[type="url"].fui-input,
input[type="password"],
input[type="password"].fui-input,
input[type="search"],
input[type="search"].fui-input,
input[type="number"],
input[type="number"].fui-input,
input[type="tel"],
input[type="tel"].fui-input,
input[type="range"],
input[type="range"].fui-input,
input[type="date"],
input[type="date"].fui-input,
input[type="month"],
input[type="month"].fui-input,
input[type="week"],
input[type="week"].fui-input,
input[type="time"],
input[type="time"].fui-input,
input[type="datetime"],
input[type="datetime"].fui-input,
input[type="datetime-local"],
input[type="datetime-local"].fui-input,
input[type="color"],
input[type="color"].fui-input,
textarea,
textarea.fui-input,
select,
select.fui-select {
    width: 100%;
    color: $color__black;
    font-size: $font__size-14;
    border: none;
    border-radius: 0px;
    padding: 10px;

    &:focus {
        // box-shadow: 0 0 3px 1px $color__green-success-25;
    }
    
    &.fui-error {

        // border-color: $color__red-error;
        &:focus {
            // box-shadow: 0 0 3px 1px $color__red-error-25;
        }
    }
}

textarea {
    width: 100%;
    min-height: 225px;
}

/*--------------------------------------------------------------
## Labels
--------------------------------------------------------------*/
.fui-form {
    .fui-field {
        .fui-field-container {
            label.fui-label {
                // color: $color__black;
                // font-weight: $font__weight-bold;
            }
        }
        
        &.fui-error {
            .fui-field-container {
                label.fui-label {
                    // color: $color__red-error;
                }
            }
        }
    }
}

/*--------------------------------------------------------------
## Checkbox
--------------------------------------------------------------*/
.fui-form {

    .fui-type-checkboxes,
    .fui-type-radio-buttons {
        .fui-fieldset {
            &.fui-layout-horizontal {
                .fui-layout-wrap {

                    .fui-checkbox,
                    .fui-radio {
                        margin-right: 16px;
                    }
                }
            }
            
            &.fui-layout-vertical {
                .fui-layout-wrap {

                    .fui-checkbox,
                    .fui-radio {
                        margin-bottom: 8px;
                    }
                }
            }
            
            .fui-legend {}
            
            .fui-input-container {
                .fui-layout-wrap {
                    .fui-checkbox {

                        input[type="checkbox"],
                        input[type="radio"] {
                            font-weight: $font__weight-bold;
                        }
                        
                        label {

                            &:before,
                            &:after {
                                position: absolute;
                                top: 2px;
                                left: 0px;
                                background-color: transparent;
                                border-color: $color__black;
                                border-radius: 0px;
                                height: 1.5rem;
                                width: 1.5rem;
                            }
                        }
                        
                        input[type="checkbox"],
                        input[type="radio"] {
                            &:checked+label {
                                &:before {
                                    // background-color: $color__yellow-bright;
                                    // border-color: $color__yellow-bright;
                                    background-image: none;
                                }
                                
                                &:after {
                                    top: 2px;
                                    left: 2px;
                                    // font-family: $font__fa-familly;
                                    content: "\f00c";
                                    display: block;
                                    font-weight: 900;
                                    font-size: 1.25rem;
                                    // color: $color__green-forest;
                                }
                            }
                            
                            &:focus+label {
                                &:before {
                                    // border-color: $color__green-success;
                                    // box-shadow: 0 0 3px 1px $color__green-success-25;
                                }
                            }
                        }
                    }
                    
                    .fui-radio {

                        input[type="checkbox"],
                        input[type="radio"] {
                            font-weight: $font__weight-bold;
                        }
                        
                        label {

                            &:before,
                            &:after {
                                position: absolute;
                                top: 2px;
                                left: 0px;
                                background-color: transparent;
                                // border-color: $color__grey-lines;
                                border-radius: 50%;
                                height: 1.5rem;
                                width: 1.5rem;
                            }
                        }
                        
                        input[type="checkbox"],
                        input[type="radio"] {
                            &:checked+label {
                                &:before {
                                    // background-color: $color__yellow-bright;
                                    // border-color: $color__yellow-bright;
                                    background-image: none;
                                }
                                
                                &:after {
                                    top: 8px;
                                    left: 6px;
                                    height: 9px;
                                    width: 9px;
                                    content: "";
                                    display: block;
                                    // background-color: $color__green-forest;
                                }
                            }
                            
                            &:focus+label {
                                &:before {
                                    // border-color: $color__green-success;
                                    // box-shadow: 0 0 3px 1px $color__green-success-25;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.checkbox-input-wrapper {
    position: relative;

    input[type="checkbox"] {
        display: none;
        font-weight: $font__weight-bold;
    }
    
    label {
        // position: relative;
        margin-left: 2rem;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            background-color: $color__white;
            border: 1px solid $color__black;
            border-radius: 0px;
            height: 1.25rem;
            width: 1.25rem;
        }
    }
    
    input[type="checkbox"] {
        &:checked+label {
            &:before {
                // border-color: $color__white;
                background-color: $color__green-primary;
                background-image: none;
            }
            
            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 1px;
                left: 3px;
                font-family: $font__fa-familly;
                content: "\f00c";
                display: block;
                font-weight: 900;
                font-size: 1rem;
                color: $color__white;
            }
        }
        
        &:focus+label {
            &:before {
                border-color: $color__green-primary;
                box-shadow: 0 0 3px 1px $color__green-primary;
            }
        }
    }
    
    input[type="checkbox"] {
        &:disabled+label {
            opacity: 0.5;
        }
    }
    
    &.dark {
        label {
            color: $color__white;
            font-weight: $font__weight-medium;

            &:before {
                // background-color: $color__white;
                border: 1px solid $color__grey-medium-light;
            }
        }
    }
}

/*--------------------------------------------------------------
## Range
--------------------------------------------------------------*/
.input-wrapper-range {
    background-color: $color__white;
    padding: 8px 10px;
    height: 55px;

    .range-value-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .range-value {
            font-size: $font__size-14;
            margin-bottom: 0px;
        }
    }
}

input[type=range] {
    box-sizing: border-box;
    appearance: none;
    width: 100%;
    margin: 0;
    padding: 0 2px;
    /* Add some L/R padding to ensure box shadow of handle is shown */
    overflow: hidden;
    border: 0;
    outline: none;
    background: linear-gradient($color__green-primary, $color__green-primary) no-repeat center;
    /* Use a linear gradient to generate only the 2px height background */
    background-size: 100% 2px;
    pointer-events: none;

    &:active,
    &:focus {
        outline: none;
    }
    
    &::-webkit-slider-thumb {
        height: 14px;
        width: 14px;
        // border-radius: 28px;
        background-color: $color__green-primary;
        position: relative;
        margin: 5px 0;
        /* Add some margin to ensure box shadow is shown */
        cursor: pointer;
        appearance: none;
        pointer-events: all;
        // box-shadow: 0 1px 4px 0.5px rgba(0, 0, 0, 0.25);
    }
}

.multi-range {
    position: relative;
    height: 20px;

    input[type=range] {
        position: absolute;

        &:nth-child(1) {
            &::-webkit-slider-thumb::before {
                background-color: $color__green-primary;
            }
        }
        
        &:nth-child(2) {
            background: none;

            &::-webkit-slider-thumb::before {
                background-color: $color__green-primary;
            }
        }
    }
}

/*--------------------------------------------------------------
## Select
--------------------------------------------------------------*/
.select-input-wrapper {
    position: relative;
    width: 100%;
    background-color: $color__white;

    &:before,
    &:after {
        position: absolute;
        display: block;
        content: "";
        top: 50%;
        width: 8px;
        height: 2px;
        background-color: $color__green-primary;
        pointer-events: none;
    }
    
    &:before {
        right: 13px;
        transform: rotate(-45deg);
    }
    
    &:after {
        right: 18px;
        transform: rotate(45deg);
    }
    
    select {
        width: 100%;
        min-width: 100%;
        appearance: none;
        color: $color__black;
        border: 0px;
        height: 55px;
        background-color: transparent;
        padding: 18px 30px 10px 10px;
        border-radius: 0;

        option {
            color: $color__black !important;
            letter-spacing: 1px;
        }
    }
    
    &.slim {
        position: relative;
        top: 2px;
        width: fit-content;
        background-color: transparent;

        &:before,
        &:after {
            top: 43%;
        }
        
        @include media-breakpoint-up(sm) {
            width: auto;
        }
        
        select {
            font-size: $font__size-14;
            width: auto;
            min-width: unset;
            height: 24px;
            padding: 0px 35px 0px 0px;
        }
    }
}

/*--------------------------------------------------------------
## Files
--------------------------------------------------------------*/
.fui-type-file-upload {
    .fui-field-container {
        position: relative;

        label {
            position: absolute;
            overflow: auto;
            clip: unset;
            width: 100%;
            text-align: center;
            font-size: 1.3125em;
            font-weight: $font__weight-medium;
            height: auto;
            top: 20px;
            display: block;
            z-index: 2;
            pointer-events: none;
        }
        
        .fui-input-container {
            position: relative;
            display: flex;
            gap: 10px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 150px;
            padding: 40px 20px 20px 20px;
            background-color: $color__white;
            border: 2px solid $color__white;
            cursor: pointer;
            transition: background .2s ease-in-out;

            input[type="file"] {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                max-width: 100%;
                color: $color__black;
                padding: 5px;
                background: transparent;
                border: none;

                &::file-selector-button {
                    display: flex;
                    // flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    width: 100%;
                    margin-right: 20px;
                    font-weight: $font__weight-bold;
                    text-decoration: underline;
                    border: none;
                    background: transparent;
                    padding: 10px 20px;
                    color: $color__green-primary;
                    cursor: pointer;

                    &:hover {
                        // background: #0d45a5;
                    }
                }
                
                &:focus {
                    border-color: transparent;
                    box-shadow: none;
                    outline: 0;
                }
            }
            
            &:hover {
                background: $color__grey-light;

                // border-color: #111;
                .drop-title {
                    // color: #222;
                }
            }
        }
    }
}

// input[type=file]::file-selector-button {
//   margin-right: 20px;
//   border: none;
//   background: #084cdf;
//   padding: 10px 20px;
//   border-radius: 10px;
//   color: #fff;
//   cursor: pointer;
//   transition: background .2s ease-in-out;
// }
input[type=file]::file-selector-button:hover {
    // background: #0d45a5;
}

/*--------------------------------------------------------------
## Submit
--------------------------------------------------------------*/
.fui-form {
    .fui-form-container {
        .fui-btn-wrapper {
            button[type="submit"].fui-submit {
                @extend .btn-lach;
                @extend .black-stroke-arrow-right;
                font-size: 14px !important;
                padding: 12px 30px 11px 30px !important;

                &:hover {
                    // border-color: $color__yellow-care;
                    // background-color: $color__yellow-bright;
                }
                
                &:focus {
                    // color: $color__grey-verydark;
                    // border: 2px solid $color__green-success;
                }
            }
        }
    }
}

/*--------------------------------------------------------------
## Required
--------------------------------------------------------------*/
.fui-form {
    .fui-field {
        .fui-field-container {
            .fui-required {
                color: $color__red-error;
            }
        }
    }
}

/*--------------------------------------------------------------
## Error messages
--------------------------------------------------------------*/
.fui-form {
    .fui-field {
        .fui-field-container {
            .fui-error-message {
                color: $color__red-error;
            }
        }
    }
}

/*--------------------------------------------------------------
## Specific form
--------------------------------------------------------------*/
.fui-form {
    .fui-form-container {
        .fui-page {
            .fui-page-container {
                .fui-page-row {
                    .fui-field-container {
                        label.fui-label {}
                        
                        .fui-input-container {}
                    }
                }
            }
        }
    }
}